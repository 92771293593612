<template>
    <button @click.native="signOutUser"
        class="group -mx-2 w-full m-0 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6                                                                                                                                                                                                                                                                   text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
        <ArrowLeftOnRectangleIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600" aria-hidden="true" />
        {{ $t('signOut') }}
    </button>
</template>

<script setup lang="ts">
import { ArrowLeftOnRectangleIcon } from "@heroicons/vue/24/outline/esm/index";
const { signOutUser } = useFirebaseAuth();
</script>