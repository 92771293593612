<template>
    <div class="hidden xl:fixed xl:inset-y-0 xl:z-40 xl:flex xl:w-72 xl:flex-col">
        <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div class="flex h-16 shrink-0 items-center">
                <NomadRxLogo />
            </div>
            <nav class="flex flex-1 flex-col">

                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <MainNavItems />
                    <li>
                        <div class="text-xs font-semibold leading-6 text-gray-400">{{ $t('providerAdmin') }}</div>
                        <ul role="list" class="-mx-2 mt-2 space-y-1">
                            <UVerticalNavigation :links="adminLinks" :ui="{
                            padding: 'px-2 py-2', base: 'mt-1', icon: {
                                base: 'w-6 h-6',
                                active: 'text-indigo-600 dark:text-indigo-200',
                                inactive: 'text-gray-400 dark:text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-200',
                            }
                        }" />
                        </ul>
                    </li>
                    <BottomNavItems />
                </ul>
            </nav>
        </div>
    </div>
</template>

<script setup>

import NomadRxLogo from "~/components/core/NomadRxLogo.vue";
import MainNavItems from "~/components/modules/provider/navigation/MainNavItems.vue";
import BottomNavItems from "~/components/modules/provider/navigation/BottomNavItems.vue";

const authStore = useAuthStore();
const { partner } = storeToRefs(authStore);

const { t } = useI18n();

useHead({
    htmlAttrs: {
        class: "h-full bg-white",
    },
    bodyAttrs: {
        class: "h-full",
    },
});

// TODO: Why is this not done using useProviderNavigation?
const adminLinks = computed(() => [
    {
        label: t('dashboard'),
        icon: 'i-heroicons-bolt',
        to: '/provider/dashboard'
    },
    // {
    //     label: 'Payments',
    //     icon: 'i-heroicons-credit-card',
    //     to: '/provider/payments/list/all'
    // },
    {
        label: t('payouts'),
        icon: 'i-heroicons-banknotes',
        to: '/provider/payouts'
    },
]);



</script>