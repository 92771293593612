<template>
    <UButton class="xl:hidden" icon="i-heroicons-bars-3-20-solid" size="sm" color="gray" square variant="ghost"
        @click="isDrawerOpen = true" />
    <USlideover v-model="isDrawerOpen" side="left" :ui="{ width: 'max-w-xs' }">
        <div class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
            <div class="flex h-16 shrink-0 items-center">
                <CoreNomadRxLogo />
            </div>
            <!-- <h1 class="text-lg font-semibold leading-5 text-gray-900">
                {{ partner.name }}</h1> -->
            <nav class="flex flex-1 flex-col gap-y-7">
                <ul role="list" class="-mx-2 space-y-1">
                    <li v-for="item in providerNavigation" :key="item.name">
                        <NuxtLink v-if="!item.disabled" :to="item.href" @click.native="closeDrawer"
                            :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                            <component :is="item.icon"
                                :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                                aria-hidden="true" />
                            {{ item.name }}
                        </NuxtLink>
                    </li>

                </ul>
                <ul>
                    <li>
                        <div class="text-xs font-semibold leading-6 text-gray-400">Provider Admin</div>
                        <ul role="list" class="-mx-2 mt-2 space-y-1">
                            <UVerticalNavigation :links="adminLinks" :ui="{
            padding: 'px-2 py-2', base: 'mt-1', icon: {
                base: 'w-6 h-6',
                active: 'text-indigo-600 dark:text-indigo-200',
                inactive: 'text-gray-400 dark:text-gray-500 group-hover:text-gray-700 dark:group-hover:text-gray-200',
            }
        }" />
                        </ul>
                    </li>
                </ul>
                <div class="mt-auto">
                    <ul>
                        <li class="">
                            <ProviderNavigationSignOutButton />
                        </li>
                        <li class="">
                            <NuxtLink :to="`/provider/profile`" @click.native="closeDrawer"
                                class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                                <IdentificationIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                                    aria-hidden="true" />
                                Account
                            </NuxtLink>
                            <NuxtLink :to="`/provider/settings`" @click.native="closeDrawer"
                                class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                                <Cog6ToothIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                                    aria-hidden="true" />
                                Settings
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </USlideover>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { Bars3Icon } from "@heroicons/vue/24/solid/esm/index";
import { Cog6ToothIcon, IdentificationIcon } from "@heroicons/vue/24/outline/esm/index";

const { providerNavigation } = useProviderNavigation();

const authStore = useAuthStore();
const { partner } = storeToRefs(authStore);

const providerConsoleStore = useProviderConsoleStore();
const { isDrawerOpen } = storeToRefs(providerConsoleStore);

const { t } = useI18n();

const adminLinks = computed(() => [
    {
        label: t('dashboard'),
        icon: 'i-heroicons-bolt',
        to: '/provider/dashboard'
    },
    // {
    //     label: 'Payments',
    //     icon: 'i-heroicons-credit-card',
    //     to: '/provider/payments/list/all'
    // },
    {
        label: t('payouts'),
        icon: 'i-heroicons-banknotes',
        to: '/provider/payouts'
    },
]);

function closeDrawer() {
    isDrawerOpen.value = false;
}
</script>
