<template>
    <div class="flex h-full">
        <SidebarDesktop />
        <div class="flex h-full flex-1 flex-col xl:pl-72">
            <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b
                        border-gray-200 bg-white px-4 shadow-sm sm:px-6 lg:px-8">
                <ProviderNavigationSidebarMobile />
                <SearchHeader />
            </div>
            <div class="overflow-y-scroll sm:pt-6 pb-24">

                <slot />
            </div>
        </div>
    </div>
</template>

<script setup>
import SearchHeader from "~/components/core/SearchHeader.vue";
import SidebarDesktop from "~/components/modules/provider/navigation/SidebarDesktop.vue";

const authStore = useAuthStore();
const { partner } = storeToRefs(authStore);

const providerConsoleStore = useProviderConsoleStore();
const { isDrawerOpen } = storeToRefs(providerConsoleStore);

const { isMobile, isMobileOrTablet } = useDevice();

useHead({
    htmlAttrs: {
        class: "h-full bg-white",
    },
    bodyAttrs: {
        class: "h-full overflow-hidden",
    },
});

</script>
<style>
#__nuxt {
    height: 100vh;
}
</style>