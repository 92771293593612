<template>
    <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div class="flex flex-1 items-center">
            <h1 class="text-lg font-bold tracking-tight">{{ partner.name }}</h1>
        </div>
        <!-- <UDropdown class="flex justify-center items-center" :items="items" :popper="{ placement: 'bottom-start' }">
            <UButton color="white" :label="locale === 'es' ? '🇲🇽' : '🇺🇸'"
                trailing-icon="i-heroicons-chevron-down-20-solid" />
        </UDropdown> -->
        <div class="flex items-center gap-x-4">
            <GlobalLocaleDropdownUpdateButton />
            <NuxtLink :to="`/provider/notifications`" class="flex items-center">
                <button class="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                    <span class="sr-only">View notifications</span>
                    <BellIcon class="h-6 w-6" aria-hidden="true" />
                </button>
            </NuxtLink>
        </div>

    </div>
</template>

<script setup lang="ts">

// import { GlobalLocaleDropdownUpdateButton } from "#build/components";
import { MagnifyingGlassIcon } from "@heroicons/vue/20/solid/esm/index";
import { BellIcon, UserCircleIcon } from "@heroicons/vue/24/outline/esm/index";

const authStore = useAuthStore();
const { partner } = storeToRefs(authStore);

const { locale } = useI18n()

onMounted(() => {
    if (process.client) {
        locale.value = localStorage.getItem('locale') || 'en'
    }
})

// const localCookie = useCookie('locale')

const items = [
    [
        {
            label: '🇺🇸 English (U.S.)',
            shortcuts: ['U'],
            click: () => {
                locale.value = "en"
                localStorage.setItem('locale', "en")
            }
        },
        {
            label: '🇲🇽 Español',
            shortcuts: ['E'],
            click: () => {
                locale.value = "es"
                localStorage.setItem('locale', "es")
            }
        }
    ],
]

const providerConsoleStore = useProviderConsoleStore()

</script>