<template>
    <li class="mt-auto">
        <div>
            <ul>
                <li class="">
                    <SignOutButton />
                </li>
                <li class="">
                    <NuxtLink :to="`/provider/profile`" @click.native="isDrawerOpen = false"
                        class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                        <IdentificationIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                            aria-hidden="true" />
                        {{ $t('account') }}
                    </NuxtLink>
                    <NuxtLink :to="`/provider/settings`" @click.native="isDrawerOpen = false"
                        class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600">
                        <Cog6ToothIcon class="h-6 w-6 shrink-0 text-gray-400 group-hover:text-indigo-600"
                            aria-hidden="true" />
                        {{ $t('settings') }}
                    </NuxtLink>
                </li>
            </ul>
        </div>
    </li>
</template>

<script setup lang="ts">

import { Cog6ToothIcon, IdentificationIcon } from "@heroicons/vue/24/outline/esm/index";
import SignOutButton from "~/components/modules/provider/navigation/SignOutButton.vue";

const providerConsoleStore = useProviderConsoleStore();
const { isDrawerOpen } = storeToRefs(providerConsoleStore);
</script>