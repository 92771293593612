<template>
    <ul role="list" class="-mx-2 space-y-1">
        <li v-for="item in providerNavigation" :key="item.name">
            <NuxtLink v-if="!item.disabled" :to="item.href" @click.native="isDrawerOpen = false"
                :class="[item.current ? 'bg-gray-50 text-indigo-600' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                <component :is="item.icon"
                    :class="[item.current ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600', 'h-6 w-6 shrink-0']"
                    aria-hidden="true" />
                {{ item.name }}
            </NuxtLink>
        </li>
    </ul>
</template>

<script setup lang="ts">

const { providerNavigation } = useProviderNavigation();

const providerConsoleStore = useProviderConsoleStore();
const { isDrawerOpen } = storeToRefs(providerConsoleStore);

</script>